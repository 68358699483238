import React, { useRef } from "react";
import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import { ifProp, prop } from "styled-tools";
import { Box, BoxProps } from "../box/Box";
import useScrollOnDrag from "../../hooks/useScrollOnDrag";

type PartiallyOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

interface ContainerProps extends BoxProps{
    showScrollBar?: boolean;
    scrollBarColor?: string;
    gap?: number; // Pixels
    itemMaxWidth?: number; // Pixels
}

const Container = styled(Box)<ContainerProps>`
    > div {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        scrollbar-width:  ${ifProp("showScrollBar", "thin", "none")};
        scrollbar-color:  ${(props) => themeGet(`colors.${String(props.scrollBarColor)}`)} transparent;
        
        // detect if a touch device to use scroll-behavior and scroll-snap-type
        // we cannot use the aforementioned css properties with mouse drag scrolling. 
        @media (pointer:coarse) {
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
        }
    }

    > div {
        display: grid;
        grid-auto-flow: column;
        column-gap: ${prop("gap")}px;
        grid-auto-columns: 85%;
        @media (${prop("theme.mediaQueries.sm")}) {
            grid-auto-columns: ${prop("itemMaxWidth")}px;
        }
    }
    
    > div > div {
        scroll-snap-align: center;
        scroll-snap-stop: normal;
    }
`;

const HorizontalScrollGrid: React.FC<React.PropsWithChildren<PartiallyOptional<ContainerProps, "gap">>> = ({
    gap,
    itemMaxWidth,
    showScrollBar = false,
    scrollBarColor = "alphaOrange",
    children,
    ...rest
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const ignoreClicks = useRef(false);
    const { events } = useScrollOnDrag(containerRef, {
        onDragStart: () => ignoreClicks.current = true
    });

    return (
        <Container
            {...rest}
            itemMaxWidth={itemMaxWidth ?? 380}
            gap={gap ?? 16}
            showScrollBar={showScrollBar}
            scrollBarColor={scrollBarColor}
            onClickCapture={(event:any) => {
                if (ignoreClicks.current) {
                    event.preventDefault();
                    ignoreClicks.current = false;
                }
            }}
        >
            <div {...events} ref={containerRef}>
                {React.Children.map(children, (child, i) => <div key={i}>{child}</div>)}
            </div>
        </Container>
    );
};

export default HorizontalScrollGrid;
