import React, { useEffect } from "react";
import { BuyBoxProps } from "../BuyBox";
import config from "../../../config";
import useSelectedProduct from "../../../hooks/useSelectedProduct";
import PromoDiscount, { getActivePromoDiscountConfig } from "../shared/PromoDiscount";
import { Box } from "../../box/Box";
import ProductSelectStockView from "./ProductSelectView";
import { FavoriteContainerButton } from "../shared/favorites";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";

export default ({
                    products,
                    promoDiscountConfigs,
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    productSelectLabel = "Product",
                    freeShippingThreshold = config.FREE_SHIPPING_THRESHOLD_DEFAULT,
                    onProductChange,
                    productNameTransform,
                    showFavoritesButton = true,
                    showFooter = true,
                    onSuccess,
                    onError,
                    buyBoxRef,
                    addToBagMiddleware,
                    productBuyBoxSelectVariant,
                    ...rest
                }: BuyBoxProps) => {
    const buyBoxSubscriptionProducts = productBuyBoxSelectVariant?.subscriptionProductIds
        ?.map((id) => products.find((prp) => prp.id === id))
        .filter((prp) => prp !== undefined) as Product[] ?? products;
    const {
        selectedProduct,
        setProduct
    } = useSelectedProduct(buyBoxSubscriptionProducts.length ? buyBoxSubscriptionProducts : products);

    useEffect(() => {
        if (onProductChange) {
            onProductChange(selectedProduct);
        }
    }, [selectedProduct, onProductChange]);

    if (!products || products.length === 0) {
        console.error("Condensed BuyBox: no products provided");
        return null;
    }

    // Map buy once product ids to their respective Products
    const buyBoxBuyOnceProducts: { [key: number]: Product } = [];
    if (productBuyBoxSelectVariant?.buyOnceProductIds) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [subscriptionProductId, buyOnceProductId] of Object.entries(productBuyBoxSelectVariant?.buyOnceProductIds)) {
            const buyOnceProduct = products.find((prp) => prp.id === buyOnceProductId);
            if (buyOnceProduct) {
                buyBoxBuyOnceProducts[parseInt(subscriptionProductId)] = buyOnceProduct;
            }
        }
    }

    // Filter specific subscriptions products if defined on product page.
    const activePromoConfig = promoDiscountConfigs
        ? getActivePromoDiscountConfig(selectedProduct.id, promoDiscountConfigs)
        : null;

    return (
        <Box ref={buyBoxRef} {...rest}>
            {activePromoConfig && <PromoDiscount promoDiscountConfig={activePromoConfig} mb={4} />}

            <ProductSelectStockView
                promoDiscountConfigs={promoDiscountConfigs}
                onSuccess={onSuccess}
                onError={onError}
                addToBagMiddleware={addToBagMiddleware}
                selectedProduct={selectedProduct}
                products={buyBoxSubscriptionProducts.length ? buyBoxSubscriptionProducts : products}
                onProductChange={setProduct}
                freeShippingThreshold={freeShippingThreshold}
                showFooter={showFooter}
                buyOnceProducts={buyBoxBuyOnceProducts}
            >
                {showFavoritesButton && (
                    <FavoriteContainerButton mt={2} productId={selectedProduct.id} />
                )}
            </ProductSelectStockView>
        </Box>
    );
};
