import React, { useEffect, useState } from "react";
import { Text } from "../../text";
import { Box } from "../../box/Box";

interface Props {
    adjustedPrice: number;
    freeShippingThreshold: number | null;
}

export default ({ adjustedPrice, freeShippingThreshold }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        window.loyaltylion?.ui?.refresh();
    }, [adjustedPrice]);

    return (
        <Box
            bg="grays.0"
            p={5}
            mt={4}
            mb={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
        >
            {freeShippingThreshold !== null && (
                <>
                    <Text
                        as="button"
                        mb={2}
                        fontSize={0}
                        textTransform="uppercase"
                        textDecoration="underline"
                        onClick={() => setIsOpen((isOpen) => !isOpen)}
                    >
                        Free Shipping Over ${freeShippingThreshold}
                        <sup>*</sup>
                    </Text>
                    {isOpen && (
                        <Text mb={4} fontSize={0} textAlign="center" maxWidth="240px">
                            <sup>*</sup>Free shipping is only available in the contiguous U.S. and excludes
                            Fitness and Digital items.
                        </Text>
                    )}
                </>
            )}

            <Text fontSize={0}>
                <a
                    href="/onnit-x/"
                    target="_blank"
                    style={{ display: "inline", textDecoration: "underline" }}
                >
                    ONNIT X REWARDS{" "}
                </a>
                {/* Loyalty Lion documentation: The attribute value should be in cents, as the component divides  */}
                {/* The amount by 100 before applying the purchase rule multiplier. @see https://developers.loyaltylion.com/sdk/components/points-for-cart */}
                <Text fontSize={0} display="inline">
                    : Earn <span data-lion-points-for={adjustedPrice * 100} /> points
                </Text>
            </Text>
        </Box>
    );
};
