import React from "react";
import { BoxProps } from "../box/Box";
import Product from "../../@types/interfaces/product/BuyBoxProduct";
import ProductType from "../../@types/enums/ProductType";
import DefaultBuyBox from "./default/DefaultBuyBox";
import ApparelBuyBox from "./apparel/ApparelBuyBox";
import { CartProductConfig } from "../../@types/interfaces/CartProductConfig";
import PromoDiscountConfigs from "../../@types/interfaces/PromoDiscountConfig";
import ProductBuyBoxSelectVariantConfig, {
    ProductBuyBoxSelectVariantTypeEnum
} from "../../@types/interfaces/ProductBuyBoxSelectVariantConfig";
import CondensedBuyBox from "./condensed/CondensedBuyBox";

export type ProductNameTransform = (name: string, pid: number) => string;

export interface BuyBoxProps extends BoxProps {
    products: Product[];
    promoDiscountConfigs?: PromoDiscountConfigs[] | null;
    freeShippingThreshold?: number;
    productSelectLabel?: string;
    onProductChange?: (product: Product) => void;
    productNameTransform?: ProductNameTransform;
    showFavoritesButton?: boolean;
    showFooter?: boolean;
    onSuccess?: () => void;
    onError?: (error: any) => void;
    buyBoxRef?: any;
    addToBagMiddleware?: (config: CartProductConfig) => Promise<CartProductConfig>;
    productBuyBoxSelectVariant?: ProductBuyBoxSelectVariantConfig;
}

const buyBoxVariant = (props: BuyBoxProps) => {
    if (props.products[0].type_id === ProductType.APPAREL_CONTAINER) {
        return (<ApparelBuyBox {...props} />);
    }

    const { productBuyBoxSelectVariant } = props;

    if (!productBuyBoxSelectVariant) {
        return (<DefaultBuyBox {...props} />);
    }

    switch (productBuyBoxSelectVariant.variant) {
        case ProductBuyBoxSelectVariantTypeEnum.CONDENSED:
            return (<CondensedBuyBox {...props} />);
        case ProductBuyBoxSelectVariantTypeEnum.DEFAULT:
        default:
            return (<DefaultBuyBox {...props} />);
    }
};
const BuyBox = (props: BuyBoxProps) => {
    if (!props.products || props.products.length === 0) {
        console.error("BuyBox: No products defined.");
        return null;
    }

    return buyBoxVariant(props);
};

export default BuyBox;
