import React, { useState } from "react";
import Box, { BoxProps } from "@onnit-js/ui/components/box/Box";
import Button, { ButtonProps } from "@onnit-js/ui/components/button/Button";
import TextField, { TextFieldProps } from "@onnit-js/ui/components/form/textfield/TextField";
import { Text } from "@onnit-js/ui/components/text";
import { TextProps } from "@onnit-js/ui/components/text/Text";
import { isValidPhoneNumber } from "../../lib/utils";

interface Props extends BoxProps {
    creativeId: string;
    inputProps?: Omit<TextFieldProps, "ref">;
    buttonProps?: ButtonProps;
    successProps?: TextProps;
    buttonLabel?: string;
    submitBtnTextColor?: string;
    successMessageText?: string;
    successFeedbackColor?: string;
    onSuccess?: Function;
    finePrintColor?: string;
    fieldAriaLabel?: string;
}

const STATUS = {
    INIT: "init",
    SUCCESS: "success",
    ERROR: "error",
};

const AttentiveSignUp = ({
    creativeId,
    inputProps,
    buttonProps,
    successProps,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    buttonLabel = "Sign Up",
    submitBtnTextColor = "white",
    successMessageText = "Thank you for signing up",
    successFeedbackColor = "earthGreen",
    onSuccess,
    finePrintColor = "grays.5",
    fieldAriaLabel = "Enter Phone",
    ...rest
}: Props) => {
    const [status, setStatus] = useState(STATUS.INIT);
    const [feedback, setFeedback] = useState("Sorry, there was an error submitting the form.");
    const [phone, setPhone] = useState("");
    const sendFormData = () => {
        if (!isValidPhoneNumber(phone)) {
            setFeedback("Please enter a valid phone number");
            setStatus(STATUS.ERROR);
            return;
        }
        const visitorId = document.cookie.replace(
            /(?:(?:^|.*;\s*)__attentive_id\s*=\s*([^;]*).*$)|^.*$/u,
            "$1"
        );
        const formData = new FormData();
        formData.append("creativeId", creativeId);
        formData.append("visitorId", visitorId);
        formData.append("phone", phone);

        fetch("/onnit/req/attentive-signup.php", {
            method: "POST",
            body: formData,
        })
            .then(() => {
                setFeedback(successMessageText);
                setStatus(STATUS.SUCCESS);
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error) => {
                console.error(error);
                setFeedback("Sorry, there was an error submitting the form.");
                setStatus(STATUS.ERROR);
            });
    };

    return (
        <Box {...rest}>
            {status === STATUS.SUCCESS ? (
                <Text
                    as="p"
                    role="alert"
                    my={3}
                    fontWeight="bold"
                    fontSize={4}
                    color={successFeedbackColor}
                    {...successProps}
                    dangerouslySetInnerHTML={{ __html: feedback }}
                />
            ) : (
                <>
                    <Box mb={3}>
                        <TextField
                            placeholder="Phone"
                            arial-label={fieldAriaLabel}
                            value={phone}
                            onChange={(event) => setPhone(event.target.value)}
                            mt={0}
                            mr={2}
                            flex="1"
                            color="black"
                            helperText="Format 0000000000"
                            error={status === STATUS.ERROR ? feedback : null}
                            {...inputProps}
                        />
                        <Button
                            mt={2}
                            width={1}
                            aria-label="Sign up for SMS alerts"
                            {...buttonProps}
                            disabled={!phone}
                            onClick={sendFormData}
                            size="medium"
                            textColor={submitBtnTextColor}
                        >
                            {buttonLabel}
                        </Button>
                    </Box>
                    <Text as="p" typeStyle="caption02" color={finePrintColor}>
                        I agree to receive recurring automated promotional and
                        personalized marketing text messages (e.g. cart reminders) from Onnit at the cell
                        number used when signing up. Consent is not a condition of any purchase. Reply HELP
                        for help and STOP to cancel. Msg frequency varies. Msg and data rates may apply.
                        View{" "}
                        <Text
                            as="a"
                            display="inline"
                            href="http://attn.tv/onnit/terms.html"
                            target="_blank"
                        >
                            Terms
                        </Text>{" "}
                        &{" "}
                        <Text
                            as="a"
                            display="inline"
                            href="https://attnl.tv/p/Kn3"
                            target="_blank"
                        >
                            Privacy
                        </Text>
                    </Text>
                </>
            )}
        </Box>
    );
};

export default AttentiveSignUp;
