import React from "react";
import Box from "@onnit-js/ui/components/box/Box";
import { List, ListItem, Text } from "@onnit-js/ui/components/text";
import BuyBox, { ProductNameTransform } from "@onnit-js/ui/components/buybox/BuyBox";
import { CheckMarkTag } from "@onnit-js/ui/components/tag/Tag";
import Product from "@onnit-js/ui/@types/interfaces/product/BuyBoxProduct";
import BazaarVoiceStarRating from "../BazaarVoiceStarRating";
import usePageData from "../../../hooks/usePageData";
import Breadcrumbs from "../Breadcrumbs";
import Recommendations from "../Recommendations";
import StickyHeader from "../StickyHeader";
import DefaultProductStickys, { useShowProductStickys } from "../../stickys/DefaultProductStickys";
import GalleryBuyAreaLayout from "./GalleryBuyAreaLayout";

interface Props {
    onProductChange?: (product: Product) => void;
    buyAreaChild?: React.ReactNode;
    productSelectLabel?: string;
    productNameTransform?: ProductNameTransform;
    stickyHeaderBtnText?: string;
}

const DefaultProductPageTop: React.FC<React.PropsWithChildren<Props>> = ({
     onProductChange,
     children,
     buyAreaChild,
     productNameTransform,
     productSelectLabel,
 }) => {
    const { pageData } = usePageData();
    const { showSticky, buyBoxRef } = useShowProductStickys();

    return (
        <>
            <StickyHeader />

            {pageData && showSticky && (
                <DefaultProductStickys
                    products={pageData.products}
                    productName={pageData.name}
                    rating={pageData.rating}
                />
            )}

            <GalleryBuyAreaLayout
                column1={children}
                column2={(
                    <>
                        {pageData && (
                            <>
                                <Breadcrumbs breadcrumbs={pageData.breadcrumbs} mb={4} />

                                <Text
                                    as="h1"
                                    fontSize={8}
                                    fontWeight="bold"
                                    fontStyle="italic"
                                    mb={2}
                                    dangerouslySetInnerHTML={{ __html: pageData.name }}
                                />

                                <BazaarVoiceStarRating product={pageData.products[0]} />

                                <Text
                                    as="p"
                                    my={6}
                                    typeStyle="default"
                                    color="grays.6"
                                    dangerouslySetInnerHTML={{ __html: pageData.description }}
                                />

                                <BuyBox
                                    buyBoxRef={buyBoxRef}
                                    products={pageData.products}
                                    promoDiscountConfigs={pageData.promoDiscounts}
                                    freeShippingThreshold={pageData.freeShippingThreshold}
                                    onProductChange={onProductChange}
                                    productNameTransform={productNameTransform}
                                    productSelectLabel={productSelectLabel}
                                    productBuyBoxSelectVariant={pageData.productBuyBoxSelectVariant}
                                    mb={4}
                                />

                                {Number(pageData.benefits?.length) > 0 && (
                                    <Box py={4}>
                                        <Text as="h2" fontSize={6} mb={3} fontWeight="bold">
                                            Benefits
                                        </Text>
                                        <List pl={5}>
                                            {pageData.benefits.map((text, index) => (
                                                <ListItem
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    key={index}
                                                    lineHeight={5}
                                                    dangerouslySetInnerHTML={{ __html: text }}
                                                    mb={1}
                                                />
                                            ))}
                                        </List>
                                    </Box>
                                )}

                                {buyAreaChild}

                                {Number(pageData.tags?.length) > 0 && (
                                    <Box py={4}>
                                        {pageData.tags.map((tag, index) => (
                                            // eslint-disable-next-line react/no-array-index-key
                                            <CheckMarkTag label={tag} key={index} m={1} />
                                        ))}
                                    </Box>
                                )}
                                <Recommendations />
                            </>
                        )}
                    </>
                  )}
            />
        </>
    );
};

export default DefaultProductPageTop;
