import React from "react";
import { Box } from "../../box/Box";
import { RadioButton } from "../../form/toggle/RadioGroup";
import { Text } from "../../text/Text";
import SubscriptionInfoModal from "../shared/SubscriptionInfoModal";
import Product from "../../../@types/interfaces/product/BuyBoxProduct";
import CostPerServing from "../shared/CostPerServing";
import CostPerCount from "../shared/CostPerCount";
import ProductCategory from "../../../@types/enums/ProductCategory";
import PromoDiscountConfig, { PromoDiscountTypeEnum } from "../../../@types/interfaces/PromoDiscountConfig";
import { getActivePromoDiscountConfig } from "../shared/PromoDiscount";
import RiskFreeLinkText from "../shared/RiskFreeLinkText";

interface Props {
    product: Product;
    promoDiscountConfigs?: PromoDiscountConfig[] | null;
    freeShippingThreshold: number;
}

const SubscriptionRadioButton = (
    {
        product,
        promoDiscountConfigs,
        freeShippingThreshold
    }: Props) => {
    const isOnSale = product.sale_discount_ratio > 0;
    const saleText = isOnSale ? `${(product.sale_discount_ratio * 100).toFixed()}% off` : "";
    const activePromoConfig = promoDiscountConfigs
        ? getActivePromoDiscountConfig(product.id, promoDiscountConfigs)
        : null;

    // -----[ Subscription ]-----
    const subscriptionEligible = product.meta["subscription-eligible"] === "yes" ?? false;
    let subscriptionPrice = product.price_subscription_raw || 0.0;
    let subscriptionPercentOff = 15;
    let subscriptionRebillDiscountPercentOff = 15;
    const subscriptionInterval = (parseInt(product.meta["default-subscription-frequency"]) || 30) / 30;
    const subscriptionMonthlyIntervalText = `Deliver every ${subscriptionInterval} month${subscriptionInterval > 1 ? "s" : ""}`;
    if (activePromoConfig?.promo_discounts_type === PromoDiscountTypeEnum.SUBSCRIPTION) {
        subscriptionPrice = (1 - activePromoConfig.unit_discount_ratio) * parseFloat(product.price_msrp_raw);
        subscriptionPercentOff = activePromoConfig.unit_discount_ratio * 100;
        if (activePromoConfig.subscription_rebill_discount_ratio) {
            subscriptionRebillDiscountPercentOff = activePromoConfig.subscription_rebill_discount_ratio * 100;
        }
    }

    const isSupplementsOrFoods = product.meta.category === ProductCategory.FOODS
        || product.meta.category === ProductCategory.SUPPLEMENTS;

    if (!subscriptionEligible) {
        return null;
    }

    return (
        <RadioButton value={product.id}>
            {parseInt(product.meta["default-subscription-frequency"]) >= 30 && (
                <Text color="grays.4" display="block">{subscriptionMonthlyIntervalText}</Text>
            )}
            <Box display="flex" alignItems="center" mb={1}>
                <Text color="grays.4">{product.name}</Text>
                <SubscriptionInfoModal />
            </Box>

            <RiskFreeLinkText />

            <p className="sr-only">Normally,</p>
            <Text display="inline-block" textDecoration="line-through" color="grays.4">
                {product.price_msrp}
            </Text>
            <p className="sr-only">Your price today,</p>
            <Text display="inline-block" fontWeight="bold" ml={1}>
                ${subscriptionPrice.toFixed(2)}
            </Text>

            <Text display="inline-block" fontWeight="bold" color="grays.4" ml={2}>
                {product.is_subscription_discount_applicable
                    ? `${subscriptionPercentOff}% off`
                    : saleText}
            </Text>
            {!product.is_subscription_discount_applicable && (
                <Text display="block" fontSize={0} color="grays.4" mt={1}>
                    Subscription rebills at {subscriptionRebillDiscountPercentOff}% off MSRP
                </Text>
            )}
            {subscriptionPrice >= freeShippingThreshold
                ? (
                    <Text display="block" fontSize={0} color="grays.4" mt={1}>
                        Free Shipping!
                    </Text>
                ) : null}
            {isSupplementsOrFoods && (
                <>
                    <CostPerServing
                        price={product.price_subscription_raw?.toString()}
                        totalServings={product.product_servings_total}
                        display="none"
                        mt={1}
                    />
                    <CostPerCount
                        price={product.price_subscription_raw?.toString()}
                        count={product.product_size}
                        unitOfMeasure={product.product_serving_unit_of_measure}
                        display="none"
                        mt={1}
                    />
                </>
            )}
        </RadioButton>
    );
};

export default SubscriptionRadioButton;
